import React from "react";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import ContactCard from "../../components/contact/contact-card-carousel"
import ContactForm from "../../components/contact/contact-form-one"

const HomeTwo = () => {
  return (
    <Layout pageTitle="Made You Love Tennis Foundation | Contact">
      <HeaderTwo />
      <StickyHeader extraClassName="stricky-header-two" />
      <ContactForm />
      <ContactCard />
      <Footer />
    </Layout>
  );
};

export default HomeTwo;
